import classNames from 'classnames'
import {
  differenceInDays,
  endOfDay,
  endOfMonth,
  endOfYear,
  format,
  isWithinInterval,
  startOfDay,
  startOfMonth,
  startOfYear,
  subDays
} from 'date-fns'
import React, { useState } from 'react'
import { BsChevronLeft, BsChevronRight, BsPlusLg, BsQuestionLg } from 'react-icons/bs'
import { IoIosMore } from 'react-icons/io'
import { HoverAction, ModalContainer, Popover, SwitchToggle, Tooltip } from 'simple-core-ui'
import { useChatContext } from '../context/ChatContext'
import s from '../styles/AIChat.scss'
import { IChat } from '../types/models'

interface SidebarProps {
  chats: IChat[]
  selectedChat: string | undefined | null
  onSelectSession: (id: string) => void
  handleDeleteChat: (id: string) => void
  handleNewChat: () => void
  handleRenameChat: (id: string, name: string) => void
}

export interface ChatSection {
  label: string
  chats: IChat[]
  from: Date
  to: Date
}

const getDynamicSection = (chat: IChat): ChatSection => {
  const today = new Date()
  const lastUpdated = new Date(chat.modified_date)
  const diff = differenceInDays(today, lastUpdated)

  if (diff > 30 && diff <= 365) {
    const month = format(lastUpdated, 'MMMM')
    return {
      label: month,
      from: startOfMonth(new Date(lastUpdated)),
      to: endOfMonth(new Date(lastUpdated)),
      chats: [chat]
    }
    // otherwise create a section for the year "2022"
  } else {
    const year = format(lastUpdated, 'yyyy')
    return {
      label: year,
      from: startOfYear(new Date(lastUpdated)),
      to: endOfYear(new Date(lastUpdated)),
      chats: [chat]
    }
  }
}

export const toSections = (sections: ChatSection[], chat: IChat): ChatSection[] => {
  const lastMessageDate = new Date(chat.modified_date)
  let correctSection = sections.find(s => {
    return isWithinInterval(lastMessageDate, {
      start: s.from,
      end: s.to
    })
  })

  if (correctSection) {
    correctSection.chats.push(chat)
  } else {
    correctSection = getDynamicSection(chat)
    sections.push(correctSection)
  }
  return sections
}

export const getStaticSections = (): ChatSection[] => {
  return [
    {
      label: 'Today',
      from: startOfDay(new Date()),
      to: endOfDay(new Date()),
      chats: []
    },
    {
      label: 'Yesterday',
      from: startOfDay(subDays(new Date(), 1)),
      to: endOfDay(subDays(new Date(), 1)),
      chats: []
    },
    {
      label: 'Previous 7 Days',
      from: startOfDay(subDays(new Date(), 7)),
      to: startOfDay(subDays(new Date(), 1)),
      chats: []
    },
    {
      label: 'Previous 30 Days',
      from: startOfDay(subDays(new Date(), 30)),
      to: startOfDay(subDays(new Date(), 7)),
      chats: []
    }
  ]
}

const PROMPT_METADATA: Record<string, Record<string, string>> = {
  sql_generation_setup: {
    label: 'SQL Generation Setup',
    description: 'This prompt tells the base ground rules for how to interact.',
    height: '300px'
  },
  table_definition: {
    label: 'Table Definition',
    description: 'This prompt tells chatgpt about the dataset',
    height: '300px'
  },
  ss_response_translation: {
    label: 'Superset Response Translation',
    description:
      'Tells chatgpt to take the response from the database and translate to human readable. In between Out of Bounds Defintion and SS Response Translation, we send the generated SQL query to superset and get the response.',
    height: '200px'
  }
}

const PromptRefinementForm = () => {
  const { data: contextData, methods } = useChatContext()

  const handleOnChange = (key: string, value: string) => {
    const newPrompts = contextData.prompts.map(prompt => {
      if (prompt.name === key) {
        return { ...prompt, content: value }
      }
      return prompt
    })

    methods.setPrompts(newPrompts)
  }

  return (
    <section>
      <div style={{ display: 'flex', marginBottom: 20, alignItems: 'center' }}>
        <SwitchToggle
          id="enableOverrides"
          checked={contextData.enableOverrides}
          onToggle={() => methods.setEnableOverrides(!contextData.enableOverrides)}
        />
        Enable Override Prompts
      </div>

      <div style={{ display: 'flex', flexDirection: 'column', gap: 16 }}>
        {contextData.prompts.map(({ name, content }) => {
          if (!PROMPT_METADATA[name]) {
            return null
          }

          return (
            <div style={{ display: 'flex', flexDirection: 'column', gap: 8 }} key={name}>
              <h4>{PROMPT_METADATA[name].label}</h4>
              <div>{PROMPT_METADATA[name].description}</div>
              <textarea
                style={{ height: PROMPT_METADATA[name].height }}
                value={content}
                onChange={e => handleOnChange(name, e.target.value)}
              />
            </div>
          )
        })}
      </div>
    </section>
  )
}

const SidebarActions = ({
  collapsed,
  handleToggleSidebar,
  handleNewChat
}: {
  collapsed: boolean
  handleToggleSidebar: () => void
  handleNewChat: () => void
}) => {
  return (
    <p className={classNames(s['sidebar-actions'], { [s['collapsed']]: collapsed })}>
      <Tooltip
        trigger={
          <span className={s['sidebar-action']} onClick={handleToggleSidebar}>
            {collapsed ? (
              <span>
                <BsChevronRight />
              </span>
            ) : (
              <span>
                <BsChevronLeft />
              </span>
            )}
          </span>
        }
        content={collapsed ? 'Open Sidebar' : 'Close Sidebar'}
      />

      <Tooltip
        trigger={
          <span className={s['sidebar-action']} onClick={handleNewChat}>
            <BsPlusLg />
          </span>
        }
        content="New Chat"
      />

      {window.credentials.user.isCSM && (
        <ModalContainer
          title="Prompt Refinement"
          content={<PromptRefinementForm />}
          cancelText="Close"
          cancelCb={() => null}
        >
          {(openModal: () => void) => {
            return (
              <Tooltip
                trigger={
                  <span className={s['sidebar-action']} onClick={openModal}>
                    <span>
                      <BsQuestionLg />
                    </span>
                  </span>
                }
                content="Prompt Refinement"
              />
            )
          }}
        </ModalContainer>
      )}
    </p>
  )
}

export const buildSections = (chats: IChat[]): ChatSection[] => {
  return chats
    .reduce(toSections, getStaticSections())
    .filter(s => !!s.chats.length)
    .sort((a, b) => b.from.getTime() - a.from.getTime())
    .map(section => {
      section.chats.sort((a, b) =>
        new Date(a.modified_date).getTime() < new Date(b.modified_date).getTime() ? 1 : -1
      )
      return section
    })
}

const SIDEBAR_STORAGE_KEY = 'ask-ai-collapsed'

const Sidebar: React.FC<SidebarProps> = ({
  chats,
  onSelectSession,
  selectedChat,
  handleNewChat,
  handleDeleteChat,
  handleRenameChat
}) => {
  const [isCollapsed, setIsCollapsed] = useState(() => {
    // Start with the value in storage, if it's present.
    const storedState = localStorage.getItem(SIDEBAR_STORAGE_KEY)
    if (storedState !== null) {
      return storedState === 'true'
    }

    // Default to collapsed if there's no chat history yet.
    const defaultIsCollapsed = !chats?.length
    localStorage.setItem(SIDEBAR_STORAGE_KEY, String(defaultIsCollapsed))
    return defaultIsCollapsed
  })

  const toggleSidebar = () => {
    setIsCollapsed((previousState: boolean) => {
      const currentState = !previousState
      localStorage.setItem(SIDEBAR_STORAGE_KEY, String(currentState))

      return currentState
    })
  }

  const visibleSections: ChatSection[] = buildSections(chats)

  return (
    <>
      <SidebarActions
        collapsed={isCollapsed}
        handleToggleSidebar={toggleSidebar}
        handleNewChat={handleNewChat}
      />
      <div className={classNames(s['sidebar'], { [s['collapsed']]: isCollapsed })}>
        <ul>
          {visibleSections.map(({ chats, label }) => {
            return (
              <React.Fragment key={label}>
                <li key={label} className={s['time-category']}>
                  {label}
                </li>
                {chats.map(chat => (
                  <li
                    key={chat.id ?? 'new-chat'}
                    className={[s['sidebar-chat'], selectedChat === chat.id && s['selected']]
                      .filter(Boolean)
                      .join(' ')}
                    onClick={e => {
                      if (chat.id === null) return
                      onSelectSession(chat.id)
                    }}
                  >
                    <span className={s['chat-name']}>{chat.name}</span>
                    {chat.id !== null ? (
                      <Popover
                        trigger={
                          <HoverAction
                            hasNewDesign
                            icon={<IoIosMore />}
                            size="small"
                            className={''}
                          />
                        }
                        triggerClassName={''}
                        actions={[
                          {
                            name: 'delete',
                            text: 'Delete',
                            onClick: e => {
                              e.stopPropagation()
                              if (chat.id === null) return
                              handleDeleteChat(chat.id)
                            }
                          },
                          {
                            name: 'rename',
                            text: 'Rename',
                            onClick: e => {
                              e.stopPropagation()
                              if (chat.id === null) return
                              handleRenameChat(chat.id, chat.name)
                            }
                          }
                        ]}
                        border
                      />
                    ) : null}
                  </li>
                ))}
                <div style={{ marginBottom: 36 }} />
              </React.Fragment>
            )
          })}
        </ul>
      </div>
    </>
  )
}

export default Sidebar
