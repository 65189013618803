import { put } from 'redux-saga/effects'
import ACT from './actions'
import { formatResponse } from '../utils/formatting'
import NotificationList from '../components/Core/NotificationList/NotificationList'
import { makeGetRequest, makePostRequest, makeDeleteRequest, makePutRequest } from '../utils/api'

// Error Codes (code - text - page)
// 403 - You do not have permission to perform this action. - 403Page
// 404 - Not found. - 404Page
// 500 - <error> - notification (no page)
// 444 - JS Error - JSErrorPage

class SagaError extends Error {
  constructor(url, ...error) {
    super(...error)
    this.name = 'SagaError'
    this.type = 'SagaError'
    this.url = url
    this.message = url + '\n' + this.message
    this.status = error[0]?.response?.status || ''
  }
}

class SagaQuest {
  constructor({ entity, debug }) {
    this.entity = entity || 'entity'
    this.debug = debug || false
    this.errorText = {
      post: `${entity} could not be created`,
      get: `${entity} could not be fetched`,
      put: `${entity} could not be updated`,
      delete: `${entity} could not be deleted`
    }
  }

  _getErrorText(operation) {
    return this.errorText[operation]
  }

  _getErrorCode = e => {
    if (
      e.response.data.detail === 'You do not have permission to perform this action.' ||
      e.response.status === 403
    ) {
      return 403
    } else if (e.response.data.detail === 'Not found.') {
      return 404
    } else {
      return 500
    }
  }

  _handleError = (e, operation) => {
    const errorCode = this._getErrorCode(e)
    if (operation === 'get' && (errorCode === 404 || errorCode === 403)) {
      return put({
        type: ACT.SHOW_ERROR_PAGE,
        payload: {
          showErrorPage: errorCode
        },
        loadingLock: 'off'
      })
    } else {
      return put({
        type: ACT.PUSH_NOTIFICATION,
        payload: {
          title: this._getErrorText(operation),
          message: <NotificationList lines={formatResponse(e.response.data)} />,
          level: 'error'
        },
        loadingLock: 'off'
      })
    }
  };

  *makeGet(url, params) {
    if (this.debug) {
      return yield makeGetRequest(url, params)
    }

    try {
      return yield makeGetRequest(url, params)
    } catch (e) {
      yield this._handleError(e, 'get')
      throw new SagaError(url, e)
    }
  }

  *makePost(url, params) {
    if (this.debug) {
      return yield makePostRequest(url, params)
    }

    try {
      return yield makePostRequest(url, params)
    } catch (e) {
      yield this._handleError(e, 'post')
      throw new SagaError(url, e)
    }
  }

  *makePut(url, params) {
    if (this.debug) {
      return yield makePutRequest(url, params)
    }

    try {
      return yield makePutRequest(url, params)
    } catch (e) {
      yield this._handleError(e, 'put')
      throw new SagaError(url, e)
    }
  }

  *makeDelete(url, params) {
    if (this.debug) {
      return yield makeDeleteRequest(url, params)
    }

    try {
      return yield makeDeleteRequest(url, params)
    } catch (e) {
      yield this._handleError(e, 'delete')
      throw new SagaError(url, e)
    }
  }
}

export default SagaQuest
